@import url(https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200&display=swap);



html {
    background: url(/static/media/rick-morty.21453120.jpg) no-repeat center center fixed;
    background-size: cover;
    text-align: center;

}

h3 {
    font-size: 5rem;
}

h4 {
    font-size: 3rem;
    font-family: 'Gemunu Libre', sans-serif;
}
.coming-soon-text{
    opacity: 0.4;
}

.maintenance-header {
    background-color: lightgrey;
}

.maintenance-text {
    color: white;
}
